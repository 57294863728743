import { UtilsString } from '@/utils/utils-string';

export default class LocalStorageConfigService {

    public KeyParaDatosVersion: string = "Version";

    public setVersion(version: string) {
        window.localStorage.setItem(this.KeyParaDatosVersion, UtilsString.ValueOf(version));
    }

    public getVersion(): string {
        let str = localStorage.getItem(this.KeyParaDatosVersion);
        if (!UtilsString.IsNullOrWhiteSpace(str)) {
            return UtilsString.ValueOf(str);
        }
        return "";
    }

    public clearVersion() {
        window.localStorage.removeItem(this.KeyParaDatosVersion);
    }

}

export const LocalStorageService = new LocalStorageConfigService();